import { Controller } from '@hotwired/stimulus';
import { apiClient } from '../../shared/apiClient';
import { disableButton, enableButton } from '../../shared/util';

export default class extends Controller {
  static targets = [];

  connect() {}

  async sendDetails(e) {
    e.preventDefault();
    const sendDetailsBtn = e.target.closest('button');
    const errorDisplay = sendDetailsBtn.parentElement.querySelector('.invalid-feedback');
    errorDisplay.classList.remove('d-block');
    disableButton(sendDetailsBtn);
    const { branchSlugs, listingSlug } = sendDetailsBtn.dataset;

    let success = true;
    try {
      const response = await apiClient(`/listings/${listingSlug}/send-details`, {
        body: { branchSlugs: JSON.parse(branchSlugs), listingSlug },
      });
    } catch (error) {
      success = false;
    }

    setTimeout(() => {
      if (success) {
        sendDetailsBtn.innerHTML =
          'Details sent<i class="fa-solid fa-check fa-sm text-success lh-sm align-middle ms-2" aria-hidden="true"></i>';
      } else {
        enableButton(sendDetailsBtn);
        errorDisplay.classList.add('d-block');
      }
    }, 400);
  }

  async sendDetailsToAll(e) {
    e.preventDefault();
    const sendDetailsToAllBtn = e.target.closest('button');
    const individualSendDetailsButtons = document.querySelectorAll('button[data-action="sell#sendDetails"]');
    const errorDisplay = sendDetailsToAllBtn.parentElement.querySelector('.invalid-feedback');
    console.log({errorDisplay})
    errorDisplay.classList.remove('d-block');
    disableButton(sendDetailsToAllBtn);
    [...individualSendDetailsButtons].forEach(button => {
      disableButton(button);
    });
    const { branchSlugs, listingSlug } = sendDetailsToAllBtn.dataset;

    let success = true;
    try {
      const response = await apiClient(`/listings/${listingSlug}/send-details`, {
        body: { branchSlugs: JSON.parse(branchSlugs), listingSlug },
      });
    } catch (error) {
      success = false;
    }

    setTimeout(() => {
      if (success) {
        sendDetailsToAllBtn.innerHTML =
          'Details sent to all traders<i class="fa-solid fa-check fa-sm text-success lh-sm align-middle ms-2" aria-hidden="true"></i>';
        [...individualSendDetailsButtons].forEach(button => {
          button.innerHTML =
          'Details sent<i class="fa-solid fa-check fa-sm text-success lh-sm align-middle ms-2" aria-hidden="true"></i>';
        });
      } else {
        enableButton(sendDetailsToAllBtn);
        [...individualSendDetailsButtons].forEach(button => {
          enableButton(button);
        });
        errorDisplay.classList.add('d-block');
      }
    }, 400);
  }
}
